import React from "react";
import { GoogleOutlined, FacebookOutlined } from "@ant-design/icons";
import { auth } from '../firebase'; // Ensure this path is correct
import { GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect } from 'firebase/auth';

const Login = () => {
    return (
        <div id="login-page">
            <div id="login-card">
                <h2>Welcome to the Chat</h2>
           
                <div
                    className="login-button google"
                    onClick={() => signInWithRedirect(auth, new GoogleAuthProvider())}
                >
                     <GoogleOutlined /> Sign In with Google
                </div>

                <br /> <br />

                <div
                    className="login-button facebook"
                    onClick={() => signInWithRedirect(auth, new FacebookAuthProvider())}
                >
                    <FacebookOutlined /> Sign In with Facebook
                </div>
            </div>
        </div>
    );
}

export default Login;
