import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD0MmVMapvnlHn711Gu4ZOYFYiQn7NGEPY",
    authDomain: "thechat-afea9.firebaseapp.com",
    projectId: "thechat-afea9",
    storageBucket: "thechat-afea9.appspot.com",
    messagingSenderId: "707472466569",
    appId: "1:707472466569:web:297d422d33ea4d77515c58"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { auth, firebaseApp };

